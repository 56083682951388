export type Tenant = {
  id: string;
  name: string;
  isPaidUp: boolean;
  isPreview: boolean;
  isReadonly: boolean;
  updateAD: boolean;
  updateCAP: boolean;
  refreshed?: string;
  updateSchedule?: RefreshCycle;
  deltaSchedule?: RefreshCycle;
  created?: string;
  createdBy?: string;
  modifiedBy?: string;
  modified?: string;
  devicesDelta?: string;
  allowWindows10?: boolean;
  updateAndroidDeviceNames?: boolean;
  androidDeviceNameFormat?: string;
  cleanUpDevices?: boolean;
  deviceCleanupAge?: number;
  hasHealthCheck?: boolean;
  hasCE?: boolean;
  androidPatchMonths?: number;
};

export type RefreshCycle = {
  start: string;
  repeat: string;
};

export enum TenantRole {
  Readonly, Admin
};

export interface IJWT {
  "aud": string;
  "iss": string,
  "iat": number,
  "nbf": number,
  "exp": number,
  "acr": string,
  "aio": string,
  "amr": string[],
  "appid": string,
  "appidacr": string,
  "deviceid": string,
  "family_name": string,
  "given_name": string,
  "ipaddr": string,
  "name": string,
  "oid": string,
  "onprem_sid": string,
  "rh": string,
  "roles": string[],
  "scp": string,
  "sub": string,
  "tid": string,
  "unique_name": string,
  "upn": string,
  "uti": string,
  "ver": string
};