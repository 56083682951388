import { useEffect, useState } from "react";
import { IisSignedIn, Tenant, TenantRole } from "../Interfaces";

const useIsSignedIn = (loadTenant?: boolean): IisSignedIn => {
    const [isSignedIn, setIsSignedIn] = useState<boolean>(false);
    const [isAdminTenant, setAdminTenant] = useState<boolean>(false);
    const [tenant, setTenant] = useState<Tenant | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(false);
    const [role, setRole] = useState<TenantRole>(TenantRole.Readonly);

    useEffect(() => {
        setIsSignedIn(true);
        setAdminTenant(false);
        setRole(TenantRole.Admin);
        if (loadTenant) setTenant({
            "allowWindows10": false,
            "androidDeviceNameFormat": "OJ{{serial}}",
            "androidPatchMonths": 6,
            "cleanUpDevices": true,
            "deviceCleanupAge": 270,
            "id": "demo",
            "hasCE":  true,
            "hasHealthCheck": true,
            "isPaidUp": true,
            "isPreview": false,
            "isReadonly": false,
            "name": "DEMO TENANT",
            "updateAD": true,
            "updateAndroidDeviceNames": true,
            "updateCAP": true
        });
        setLoading(false);
    }, [loadTenant]);

    return { isSignedIn, isAdminTenant, tenant, loading, role };
}

export default useIsSignedIn;