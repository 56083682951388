import { Tenant } from "../Interfaces/Tenant";
import { Client } from '@microsoft/microsoft-graph-client';

export class JTool {
    readonly tenant?: Tenant;
    readonly client?: Client;
    constructor(Tenant?: Tenant, Client?: Client, ToasterId?: string) {
        this.tenant = Tenant;
        this.client = Client;
        this.toasterId = ToasterId;
    }
    toasterId?: string;
    makePath = (path: string, noV1: boolean = false): string => path.startsWith("http") ? path : (`https://ce-db-service.azurewebsites.net${noV1 ? '' : '/v1.0'}${path.startsWith('/') ? path : `/${path}`}`);
}