export type LogEntry = {
    id: string;
    parentId: string,
    date: string;
    type: string;
    message: string;
    details: string;
    tenant?: string;
    source?: string;
  };

export enum LogType { Tenant, Feed };

export enum LogEntryType { Critical, Error, Warning, Information, Verbose, Start, Stop, Suspend, Resume, Transfer };

export enum LogEntrySource { PolicyUpdate, DeviceUpdate, AndroidRename, DeviceCleanup, FeedUpdate };