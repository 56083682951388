import React, { startTransition, useContext } from 'react';
import {
  Accordion, AccordionHeader, AccordionItem, AccordionPanel, Button, Caption1, Card,
  CardHeader, CardPreview, Divider, Link, Text, Title1
} from '@fluentui/react-components';
import { 
  OpenRegular, OpenFilled, bundleIcon, PlayRegular, PlayFilled, AlbumFilled, AlbumRegular,
  HeartPulseFilled, HeartPulseRegular, LockClosedKeyFilled, LockClosedKeyRegular
} from '@fluentui/react-icons';
import useIsSignedIn from '../Hooks/useIsSignedIn';
import JToolContext from '../Components/JToolContext';
import { Alert } from '@fluentui/react-components/unstable';
import { useNavigate } from 'react-router-dom';
import { Providers } from '@microsoft/mgt-element';
import { TenantRole } from '../Interfaces';

export default function HomePage(props: { loading?: boolean }) {
  const { isSignedIn, role } = useIsSignedIn();
  const { tenant, makePath } = useContext(JToolContext);
  const navigator = useNavigate();
  const OpenIcon = bundleIcon(OpenFilled, OpenRegular);
  const PlayIcon = bundleIcon(PlayFilled, PlayRegular);
  const ReportIcon = bundleIcon(AlbumFilled, AlbumRegular);
  const Health = bundleIcon(HeartPulseFilled, HeartPulseRegular);
  const Key = bundleIcon(LockClosedKeyFilled, LockClosedKeyRegular);

  const navigate = (url: string) => {
    startTransition(() => navigator(url));
  };

  if (!isSignedIn && !props.loading) return (
    <section>
      <div className='stack center' style={{ marginTop: 20 }}>
        <Text>Please consent to the Database Service</Text>
        <Button as='button' appearance='primary' onClick={() => window.open(makePath("/Tenants/consent", true))} iconPosition="after" icon={<OpenIcon />}>Consent</Button>
        <Text>Once consented you can just the roles users have in this app via the Jisc CE/M365 Companion Service Enterprise app in Entra Id</Text>
        <Text>You will need to the role of Admin to edit anything</Text>
      </div>
      <div className='stack center' style={{ marginTop: 20 }}>
        <Button appearance='primary' icon={<Key />} size="large" iconPosition="after" onClick={() => Providers.globalProvider.login ? Providers.globalProvider.login() : undefined}>Login</Button>
      </div>
    </section>
  );
  return (
    <section>
      {!tenant && !props.loading && <Alert intent="error">
        Tenant not available, an admin will need to create it for you, please provide your tenant id to&nbsp;<Link href="mailto:cloud@jisc.ac.uk?CE DB Sign Up">cloud@jisc.ac.uk</Link>
      </Alert>}
      {tenant && <div className='stack' style={{ gap: 20 }}>
        <Title1 style={{ textAlign: "center" }}>Welcome to the Jisc CE/M365 Companion Service</Title1>
        <div className="stack hoz wrap cards">

          {(tenant.isPaidUp || tenant.isPreview || tenant.isReadonly) && <>
            <Card className="card" onSelectionChange={(e, d) => { e.preventDefault(); if (d.selected) navigate("tenant"); }}>
              <CardPreview>
                <img className="smallradius" src="/Assets/start.jpg" alt="Start with tenant settings" />
              </CardPreview>
              <CardHeader header={<Text weight="semibold">Start: Tenant Settings</Text>}
                description={<Caption1>You can see what's enabled and configure additional tool configuration for your tenant</Caption1>} action={<>
                  <PlayIcon />
                </>} />
            </Card>
            {tenant.hasCE && <Card className="card" onSelectionChange={(e, d) => { e.preventDefault(); if (d.selected) navigate("reports"); }}>
              <CardPreview>
                <img className="smallradius" src="/Assets/summary.jpg" alt="Run reports against your tenant" />
              </CardPreview>
              <CardHeader header={<Text weight="semibold">Run reports</Text>}
                description={<Caption1>See which devices are connecting to your tenant and run CE summary reports</Caption1>} action={<>
                  <ReportIcon />
                </>} />
            </Card>}
            {tenant.hasHealthCheck && role === TenantRole.Admin && <Card className="card" onSelectionChange={(e, d) => { e.preventDefault(); if (d.selected) navigate("health"); }}>
              <CardPreview>
                <img className="smallradius" src="/Assets/health.jpg" alt="Give your tenant a health check" />
              </CardPreview>
              <CardHeader header={<Text weight="semibold">Health Check</Text>}
                description={<Caption1>Give your tenant a Jisc health check with our recommendations</Caption1>} action={<>
                  <Health />
                </>} />
            </Card>}
          </>}
        </div>
        <Divider />
        <Text>Below are some detail on the sources in use in the tool</Text>
        <Accordion collapsible>
          <AccordionItem value="Android">
            <AccordionHeader>Android Enterprise Partners DB</AccordionHeader>
            <AccordionPanel>
              <div className='stack'>
                <Text>We pull data back from the <Link href='https://androidenterprisepartners.withgoogle.com/' target='_blank'>Android Enterprise Parters</Link> database as a primary source for Android.</Text>
                <Text>Android Enterprise Partners DB feed is processed after EndOfLife.date to write correct info</Text>
                <Text>We write the lowest supported OS into your policies if Policy update is enabled</Text>
              </div>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="Apple">
            <AccordionHeader>Apple</AccordionHeader>
            <AccordionPanel>
              <div className='stack'>
                <Text>For Apple devices we use the MacOS and iOS EndOfLife.date feeds to assertain device support.</Text>
                <Text>If a device is not running a supported Apple OS the device is deemed as end of life.</Text>
                <Text>EndOfLife iOS/MacOS feeds are updated daily, if you enable Policy Updating the lowest supported build number is added to the policies</Text>
              </div>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="Dell">
            <AccordionHeader>Dell Specific Info</AccordionHeader>
            <AccordionPanel>
              <div className='stack'>
                <Text>For Dell devices provide the additional admin consent and create a Tenant level feed for Dell with the details below, refreshing is not supported.</Text>
                <Text>Aquire some of these details from <Link href="https://tdm.dell.com/" target="_blank">Dell Tech Direct</Link></Text>
                <Text>As a new model is found it is looked up and that model is then added to the DB as a tenant model, you update devices via this entry.</Text>
                <div className='stack hoz'>
                  <Text style={{ width: 150 }}>Feed URL</Text>
                  <Text>https://apigtwb2c.us.dell.com/PROD/sbil/eapi/v5/asset-entitlements</Text>
                </div>
                <div className='stack hoz'>
                  <Text style={{ width: 150 }}>Manufacturers</Text>
                  <Text>Dell</Text>
                </div>
                <div className='stack hoz'>
                  <Text style={{ width: 150 }}>OAuth Key</Text>
                  <Text>From Dell Tech Direct</Text>
                </div>
                <div className='stack hoz'>
                  <Text style={{ width: 150 }}>OAuth Secret</Text>
                  <Text>From Dell Tech Direct</Text>
                </div>
              </div>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="EndofLife.date">
            <AccordionHeader>EndofLife.date</AccordionHeader>
            <AccordionPanel>
              <div className='stack'>
                <Text>We have default feeds for EndOfLife.date, you can add additional tenant sepecific feeds via the feed menu</Text>
              </div>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="Samsung">
            <AccordionHeader>Samsung</AccordionHeader>
            <AccordionPanel>
              <div className='stack'>
                <Text>We use EndOfLife for Samsung devices, the system will try and get models from Samsung's device API where possible.</Text>
                <Text>After the EOL feed is processed the Android Enterprise Partners DB will take precedance on EOL data.</Text>
              </div>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="Windws">
            <AccordionHeader>Windows</AccordionHeader>
            <AccordionPanel>
              <div className='stack'>
                <Text>Windows build versions are sourced from learn.microsoft.com. When Policy updates are enabled the supported build number<b style={{ textDecoration: 'underline' }}>s</b> are set on the relavent policies.</Text>
              </div>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </div>}
    </section>
  );
}