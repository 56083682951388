import { PresenceBadgeStatus, TableRowId } from "@fluentui/react-components";

export type IComparer = "LessThan" | "LessThanEquals" | "Equals" | "GreaterThanEquals" | "GreaterThan" | "NotEquals";

export type IHealthCheckType = "#Jisc.CEM365.Models.HealthCheckCountSetting" | "#Jisc.CEM365.Models.HealthCheckValueSetting";

export interface IHealthCheck {
    id?: string;
    "@odata.type"?: IHealthCheckType|string|null;
    name: string;
    displayName: string;
    reason: string;
    priority?: Priority|string|null;
    impact?: Impact|string|null;
    moreInfoLink?: string|null;
    created?: string|null;
    createdBy?: string|null;
    modified?: string|null;
    modifiedBy?: string|null;
};

export interface IHealthCheckCountSetting extends IHealthCheck {
    count: number;
    compare: IComparer;
    "@odata.type": "#Jisc.CEM365.Models.HealthCheckCountSetting";
};

export interface IHealthCheckValueSetting<T> extends IHealthCheck {
    value: T;
    not?: boolean;
    "@odata.type": "#Jisc.CEM365.Models.HealthCheckValueSetting";
};

export const HCCheck = <T>(setting: IHealthCheckCountSetting | IHealthCheckValueSetting<T>|undefined, value: T, fallback?: PresenceBadgeStatus): PresenceBadgeStatus => {
    if (typeof value === "string" && value.match(/True|False/gi)) value = JSON.parse(value.toLowerCase()) as T;
    if (setting === undefined) return fallback ?? "unknown";
    else if (setting["@odata.type"] === "#Jisc.CEM365.Models.HealthCheckCountSetting") {
        const v = value as number;
        switch (setting.compare) {
            case "LessThan": return v < setting.count ? "available" : "blocked";
            case "LessThanEquals": return v <= setting.count ? "available" : "blocked";
            case "Equals": return v === setting.count ? "available" : "blocked";
            case "GreaterThanEquals": return v >= setting.count ? "available" : "blocked";
            case "GreaterThan": return v > setting.count ? "available" : "blocked";
            case "NotEquals": return v !== setting.count ? "available" : "blocked";
            default: return fallback ?? "unknown";
        }
    } else if (typeof setting.value === "object" && Array.isArray(setting.value) && !Array.isArray(value)) {
        const incs = (setting.value as Array<any>).includes(value);
        const incsString = (setting.value as Array<any>).find(v => v.toString() === (value as any).toString());
        const find = (setting.value as Array<any>).find(v => JSON.stringify(v) === JSON.stringify(value));
        return (incs || incsString || find) ? (setting.not ? "blocked" : "available") : "blocked";
    } else if (typeof value === "object" && !Array.isArray(value) && typeof setting.value === "string") 
        return JSON.stringify(value) === decodeURI(setting.value) ? (setting.not ? "blocked" : "available") : "blocked";
    else if ((Array.isArray(setting.value) && Array.isArray(value)) || ((typeof setting.value === "object" && typeof value === "object"))) 
        return JSON.stringify(setting.value).toLowerCase() === JSON.stringify(value).toLowerCase() ? (setting.not ? "blocked" : "available") : "blocked";
    else return setting.value === value ? (setting.not ? "blocked" : "available") : "blocked";
};

export interface IHealthCheckPageProps {
    loading: boolean;
    setLoading: (v: boolean) => void;
    getSetting: (name: string) => IHealthCheck | undefined;
};

export type SettingArea = "Password Rule Settings" | "Group.Unified";

export type Priority = "Low"|"Standard"|"High"|"Urgent"|"Critical"|"Informative";

export type Impact = "Low"|"Medium"|"High"|"Severe";

export interface IHealthCheckRowItem {
    id: string;
    displayName: string;
    value?: any;
    setting?: IHealthCheck;
    type?: "SharePoint"|"Other"|undefined;
}

export interface IWrappedHealthCheckRowItem {
    settings: IHealthCheckRowItem[];
    displayName: string;
}

export interface ISelectedHealthCheckRowItem extends IHealthCheckRowItem {
    root: string;
    selection: TableRowId;
}