import { lazy, useEffect, useState, useTransition } from 'react';
import {
  Home32Regular, Home32Filled, bundleIcon, BuildingRegular, BuildingFilled,
  TrayItemAddFilled, TrayItemAddRegular, ArrowSyncCheckmarkFilled, ArrowSyncCheckmarkRegular,
  SlideSettingsFilled, SlideSettingsRegular, HeartPulseRegular, HeartPulseFilled,
  AlbumFilled, AlbumRegular
} from '@fluentui/react-icons';
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import './App.css';
import { ThemeToggle } from '@microsoft/mgt-react';
import {
  FluentProvider, Link, SelectTabData, SelectTabEvent, Tab, TabList, ProgressBar, Accordion,
  AccordionItem, AccordionHeader, AccordionPanel, useId, Toaster, Title1
} from '@fluentui/react-components';
import useTheme from './Hooks/useTheme';
import logo from './logo.svg';
import useIsSignedIn from './Hooks/useIsSignedIn';
import { LogType, TenantRole } from './Interfaces';
import JToolContext from './Components/JToolContext';
import { JTool } from './Components/JTool';
import HomePage from './Pages/HomePage';

const Tenants = lazy(() => import('./Pages/TenantsPage'));
const Manufacturers = lazy(() => import('./Pages/ManufacturersPage'));
const Models = lazy(() => import('./Pages/ModelsPage'));
const OSs = lazy(() => import('./Pages/OSPage'));
const Feeds = lazy(() => import('./Pages/FeedPage'));
const Logs = lazy(() => import('./Pages/LogsPage'));
const HealthCheck = lazy(() => import('./Pages/HealthCheckPage'));
const Reports = lazy(() => import('./Pages/ReportsPage'));
const Permissions = lazy(() => import('./Pages/PermissionsPage'));

function App() {
  const Home32 = bundleIcon(Home32Filled, Home32Regular);
  const Building = bundleIcon(BuildingFilled, BuildingRegular);
  const ManufacturerIcon = bundleIcon(TrayItemAddFilled, TrayItemAddRegular);
  const Feed = bundleIcon(ArrowSyncCheckmarkFilled, ArrowSyncCheckmarkRegular);
  const OSIcon = bundleIcon(SlideSettingsFilled, SlideSettingsRegular);
  const Health = bundleIcon(HeartPulseFilled, HeartPulseRegular);
  const ReportIcon = bundleIcon(AlbumFilled, AlbumRegular);
  const [isPending, startTransition] = useTransition();
  const toasterId = useId("toaster");
  const [isDark, theme] = useTheme();
  const [selectedNav, setNav] = useState<string>("home");
  const { isSignedIn, isAdminTenant, tenant, loading, role } = useIsSignedIn(true);
  const navigator = useNavigate();

  const tabSelect = (event: SelectTabEvent, data: SelectTabData) => {
    setNav(data.value as string);
    startTransition(() => navigator(`/${(data.value as string) === 'home' ? '' : (data.value as string)}`));
  };

  const location = useLocation();

  useEffect(() => startTransition(() => setNav(location.pathname === '/' ? 'home' : location.pathname.split('/').at(1)!)), [location]);

  return (
    <FluentProvider theme={theme}>
      <JToolContext.Provider value={new JTool(tenant, undefined, toasterId)}>
        <div id="jtool" className={`App ${isDark ? 'mgt-dark' : ''}`}>
          <div style={{ display: "none", visibility: "hidden" }}><ThemeToggle darkModeActive={isDark} /></div>
          <header id="header">
            <Link href="https://www.jisc.ac.uk" appearance='subtle' className="App-logo">
              <img src={logo} alt="logo" />
              <Title1 style={{fontWeight: 'bold', fontSize: 26}}>M365 Companion Service - Proof of Concept</Title1>
            </Link>
          </header>
          <nav id="nav">
            <TabList defaultSelectedValue="home" selectedValue={selectedNav} onTabSelect={tabSelect} appearance='subtle' vertical size='large' className='Menu'>
              <Tab value="home" icon={<Home32 />}>Home</Tab>
              {isSignedIn && !loading && tenant && <>
                {(tenant.hasCE || isAdminTenant) && (tenant.isPaidUp || tenant.isPreview || tenant.isReadonly) && <Tab value="tenant" icon={<Building />}>CE Tenant</Tab>}
                {(tenant.isPaidUp || tenant.isPreview || tenant.isReadonly) && <Tab value="reports" icon={<ReportIcon />}>Reports</Tab>}
                {tenant.hasHealthCheck && role === TenantRole.Admin && (tenant.isPaidUp || tenant.isPreview || tenant.isReadonly) && <Tab value="health" icon={<Health />}>Health Check</Tab>}
                {(tenant.isPaidUp || tenant.isPreview || tenant.isReadonly) &&
                  <Accordion collapsible>
                    {(tenant.hasCE || isAdminTenant) && <AccordionItem value="Databse">
                      <AccordionHeader size='large'>CE Database</AccordionHeader>
                      <AccordionPanel>
                        <Tab value="feeds" icon={<Feed />}>Feeds</Tab>
                        <Tab value="manufacturers" icon={<ManufacturerIcon />}>Manufacturers</Tab>
                        <Tab value="os" icon={<OSIcon />}>OSs</Tab>
                      </AccordionPanel>
                    </AccordionItem>}
                  </Accordion>}
              </>}
            </TabList>
          </nav>
          <div id="content">
            {(loading || isPending) && <ProgressBar />}
            {<Routes>
              <Route path="*" element={<HomePage loading={loading} />} />
              <Route path="/permissions" element={<Permissions />} />
              {isSignedIn && tenant && <>
                {(tenant.hasCE || isAdminTenant) && (tenant.isPaidUp || tenant.isPreview || tenant.isReadonly) && <>
                  <Route path="/tenant" element={<Tenants />} />
                  <Route path="/tenant/logs" element={<Logs Mode={LogType.Tenant} />} />
                  <Route path="/os" element={<OSs />} />
                  <Route path="/feeds" element={<Feeds />} />
                  <Route path="/feeds/:id/logs" element={<Logs Mode={LogType.Feed} />} />
                  <Route path="/manufacturers" element={<Manufacturers />} />
                  <Route path="/manufacturers/:id/models" element={<Models />} />
                </>}
                {tenant.hasCE && (tenant.isPaidUp || tenant.isPreview || tenant.isReadonly) && <Route path="/reports/*" element={<Reports />} />}
                {tenant.hasHealthCheck && role === TenantRole.Admin && <Route path="/health/*" element={<HealthCheck />} />}
              </>}
            </Routes>}
          </div>
        </div>
        <Toaster toasterId={toasterId} />
      </JToolContext.Provider>
    </FluentProvider>
  );
}

export default App;